<template>
  <div class="s3-object-list">
    <form class="review-form">
      <label for="review">Json:</label>
      <textarea id="review" :value="prettyConfiguration" @input="updateConfiguration($event.target.value)"></textarea>
    </form>
  </div>
</template>
  
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  mounted() {
  },
  computed: {
    ...mapGetters(['getUser', 'getTokens', 'getConfiguration']),
    prettyConfiguration () {
      return JSON.stringify(this.getConfiguration, undefined, 4);
    }
  },
  methods: {
    ...mapActions(['setUser', 'setTokens', 'setConfiguration']),
    updateConfiguration(value) {
      try {
        var json = JSON.parse(value);
        this.setConfiguration(json);
      } catch (e) {
        console.error(e)
      }
    }
  },
};
</script>
<style scoped>
.review-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  background-color: white;
  -webkit-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 2px 15px -12px rgba(0, 0, 0, 0.57);
  box-shadow: 2px 15px -12px rgba(0, 0, 0, 0.57);
}

.review-container li {
  margin-bottom: 30px;
}

.review-form label {
  text-align: left;
}

.review-form textarea {
  height: 150px;
}

.review-form .button {
  display: block;
  margin: 30px auto;
}
</style>
  