<template>
    <div class="s3-object-list">
        <span>
            <h2 class="s3-object-list__title">{{ title }}</h2>
            <span>
                <label for="prefix">Prefix </label>
                <input type="text" v-model="prefix" id="prefix" />
                <button @click="listS3Objects" class="header__button">LOAD</button>
            </span>
        </span>
        <ul class="s3-object-list__list">
            <li v-for="object in s3Objects" :key="object.Key" class="s3-object-list__item" @click="download(object)">
                {{ object.Key }}
            </li>
        </ul>
        <p v-if="message!=null">{{message}}</p>
    </div>
</template>
  
<script>
import AWS from 'aws-sdk';
import { mapGetters } from 'vuex';
import { parseJwt } from '@/utils/auth.js'

export default {
    props: ['title', 'type', 'suffix'],
    data() {
        return {
            s3Objects: [],
            message: null,
            prefix: ""
        };
    },
    mounted() {
        const idToken = this.getTokens.idToken;
        if (idToken == null) {
            alert("No token");
            return;
        }
        const payload = parseJwt(idToken);
        if (this.type == "shared-documents") {
            this.prefix = `${payload['custom:tenant_id']}/${this.type}/`;
        } else if (this.suffix !== ""){
            this.prefix = `${payload['custom:tenant_id']}/${this.type}/${payload['custom:client_id']}/${this.suffix}/`;
        } else {
            this.prefix = `${payload['custom:tenant_id']}/${this.type}/${payload['custom:client_id']}/`;
        }
        this.listS3Objects();
    },
    computed: {
        ...mapGetters(['getUser', 'getTokens', 'getConfiguration']),
    },
    methods: {
        async download (o) {
            console.log(o);
            var s3 = new AWS.S3();
            const url = await s3.getSignedUrl('getObject', {
                Bucket: this.getConfiguration.bucket,
                Key: o.Key,
                Expires: 60
            })
            window.open(url, '_blank');
        },
        async listS3Objects() {
            const identityProvider = this.getConfiguration.oauth_server.replace(/^https?:\/\//, '');
            const credentials = new AWS.CognitoIdentityCredentials({
                IdentityPoolId: this.getConfiguration.identity_pool_id, // process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL,
                Logins: {
                    [identityProvider]: this.getTokens.idToken
                }
            });
            const region = this.getConfiguration.region;
            AWS.config.update({
                region, credentials
            });

            var s3 = new AWS.S3();

            var params = {
                Bucket: this.getConfiguration.bucket,
                Prefix: this.prefix,
                Delimiter: '/'
                // Prefix: `${AWS.config.credentials.identityId}/`,
            }

            try {
                const response = await s3.listObjects(params).promise();
                this.s3Objects = response.Contents;
                this.message = "";
            } catch (error) {
                console.error(error?.originalError?.originalError?.message);
                this.s3Objects = [];
                this.message = (error?.originalError?.originalError?.message != null) ? error?.originalError?.originalError?.message : error.message
                console.error('Failed to list S3 objects:', error);
            }
        }
    },
};
</script>
<style scoped>
.s3-object-list {
    margin: 10px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.s3-object-list > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.s3-object-list > span > span > input[type=text] {
    width: 250px;
}

.s3-object-list > span > span > * {
    margin-right: 8px;
}
.s3-object-list__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.s3-object-list__list {
    list-style-type: none;
    padding: 0;
    display: inline;
}

.s3-object-list__item {
    margin: 0.2rem;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}
.s3-object-list__item:hover {
    color: #ffffff;
    background-color: #0056b3;
}
</style>
  