<template>
  <div class="container">
    <HeaderMenu />
    <S3List title="Mes documents" type="client-documents" suffix="" v-if="isLoggedIn" />
    <S3List title="Mes documents partagés" type="client-documents" suffix="shared-documents" v-if="isLoggedIn" />
    <S3List title="Documents partagés" type="shared-documents" suffix="" v-if="isLoggedIn" />
    <S3Upload title="Upload" v-if="isLoggedIn" />
  </div>
</template>

<script>
import HeaderMenu from './components/HeaderMenu.vue'
import S3List from './components/S3List.vue'
import S3Upload from './components/S3Upload.vue'
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    HeaderMenu,
    S3List,
    S3Upload
  },
  beforeMount() {
    this.$store.commit('loadLocalStorage');
  },
  computed: {
    ...mapGetters(['getUser']),
    isLoggedIn() {
      return this.getUser != null;
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.container {
  margin: auto;
  max-width: 960px;
  /* Adjust the maximum width as per your design */
}

input[type=text] {
  padding: 6px;
  margin: 8px 0;
  box-sizing: border-box;
}

.header__button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}

.header__button:hover {
  background-color: #0056b3;
}
</style>
