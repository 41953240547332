import { createApp } from 'vue'
import App from './App.vue'
import store from './store/store';

var $ = require('jquery');
window.$ = $;

store.subscribe( (mutation, state) => {
    localStorage.setItem('configuration', JSON.stringify(state.configuration));  
})

createApp(App).use(store).mount('#app')
