<template>
  <header class="header">
    <div>
      <button class="header__button" @click="handleConfiguration">Configuration</button>
      <h1 class="header__title">{{ title }}</h1>
    </div>
    <div v-if="isLoggedIn" class="header__user">
      <p>Welcome, {{ userName }}!</p>
      <button class="header__button" @click="handleLogout">Logout</button>
    </div>
    <button v-else class="header__button" @click="handleLogin">Login</button>
    <Modal width="800px" title="Configuration de l'application" v-model:visible="isVisible" :okButton="{
      onclick: submitConfiguration,
      loading: true
    }">
      <div>
        <AppConfiguration />
      </div>
    </Modal>
  </header>
</template>
  
<script>
import { ref } from 'vue';
import { Modal } from 'usemodal-vue3';
import AppConfiguration from './AppConfiguration.vue'
import { mapGetters, mapActions } from 'vuex';
import { AuthorizationServiceConfiguration, FetchRequestor, BaseTokenRequestHandler, AuthorizationNotifier, RedirectRequestHandler, AuthorizationRequest, LocalStorageBackend, BasicQueryStringUtils, DefaultCrypto, GRANT_TYPE_AUTHORIZATION_CODE, TokenRequest } from '@openid/appauth';

class MyStringUtils extends BasicQueryStringUtils {
  constructor() {
    super();
    this.parse = (input) => super.parse(input, false);
  }
}

export default {
  components: {
    Modal,
    AppConfiguration
  },
  data() {
    return {
      title: 'My Ged',
      isVisible: ref(false)
    };
  },
  created() {
    this.notifier = new AuthorizationNotifier();
    this.authorizationHandler = new RedirectRequestHandler(new LocalStorageBackend(), new MyStringUtils(), window.location, new DefaultCrypto());
    this.authorizationHandler.setAuthorizationNotifier(this.notifier);
    this.notifier.setAuthorizationListener((request, response, error) => {
      console.info('Authorization request complete', request, response, error);
      this.code = response.code;
      this.stateRequest = request;
      if (this.getUser == null) {
        this.getToken().then(r => console.log(r))
      }
    });
    this.authorizationHandler.completeAuthorizationRequestIfPossible()
  },

  computed: {
    ...mapGetters(['getUser', 'getTokens', 'getConfiguration']),
    userName() {
      return this.getUser != null ? this.getUser.email : 'noone';
    },
    isLoggedIn() {
      return this.getUser != null;
    },
  },

  methods: {
    ...mapActions(['setUser', 'setTokens', 'setConfiguration']),
    async handleConfiguration() {
      this.isVisible = true;
    },
    submitConfiguration() {
      setTimeout(() => {
        this.isVisible = false
      }, 1000)
    },
    async handleLogout() {
      if (!this.configuration) {
        await this.loadAuthConfiguration();
      }
      // Get the user info using the access token
      // const idToken = this.getTokens.idToken;
      window.location = `${this.getConfiguration.server_domain}/logout?client_id=${this.getConfiguration.public_client_id}&logout_uri=https://${window.location.hostname}`;
      this.setUser(null);
      this.setTokens(null);
      // console.log(revokeTokenResponse);
    },

    async loadAuthConfiguration() {
      if (this.getConfiguration.oauth_server === undefined) {
        alert("Fill oauth_server configuration before Sign-In ");
        return;
      }
      console.log(`Fetch auth configuration from ${this.getConfiguration.oauth_server}`);
      await AuthorizationServiceConfiguration.fetchFromIssuer(this.getConfiguration.oauth_server)
        .then(response => {
          console.log(response);
          this.configuration = response;
        })
        .catch(error => {
          console.error('Something bad happened', error);
        });
    },

    async handleLogin() {
      if (!this.configuration) {
        await this.loadAuthConfiguration();
      }
      // create a request
      const authorizationHandler = new RedirectRequestHandler();
      let request = new AuthorizationRequest({
        client_id: this.getConfiguration.public_client_id,
        redirect_uri: process.env.VUE_APP_OPENID_REDIRECT_URL,
        scope: process.env.VUE_APP_OPENID_SCOPE,
        response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
        state: undefined,
        extras: { 'prompt': 'consent', 'access_type': 'offline' }
      });

      if (this.configuration) {
        // make the authorization request
        authorizationHandler.performAuthorizationRequest(this.configuration, request);
      }
    },

    async getToken() {
      if (!this.configuration) {
        await this.loadAuthConfiguration();
      }
      let request;
      const tokenHandler = new BaseTokenRequestHandler(new FetchRequestor(), new MyStringUtils());
      if (this.code) {
        let extras;
        if (this.stateRequest && this.stateRequest.internal) {
          extras = {};
          extras['code_verifier'] = this.stateRequest.internal['code_verifier'];
        }

        request = new TokenRequest({
          client_id: this.getConfiguration.public_client_id,
          redirect_uri: process.env.VUE_APP_OPENID_REDIRECT_URL,
          grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
          code: this.code,
          refresh_token: undefined,
          extras: extras
        });
      }
      const tokenResponse = await tokenHandler.performTokenRequest(this.configuration, request);
      this.setTokens(tokenResponse);
      console.log('Token response', this.configuration)

      // Get the user info using the access token
      const userInfoResponse = await fetch(this.configuration.userInfoEndpoint, {
        headers: {
          Authorization: `Bearer ${tokenResponse.accessToken}`,
        },
      }).then(response => response.json());
      this.setUser(userInfoResponse);
    }
  },
};
</script>
  
<style scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: #ffac47;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header>div {
  display: flex;
}

.header__title {
  font-size: 24px;
  margin: 0 0 0 5px;
}

.header__user {
  display: flex;
  align-items: center;
}

.header__user p {
  margin: 0;
  margin-right: 8px;
  font-weight: bold;
}
</style>
  