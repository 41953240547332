<template>
    <div class="s3-object-list">
        <span>
            <h2 class="s3-object-list__title">{{ title }}</h2>
            <span>
                <label for="prefix">Prefix </label>
                <input type="text" v-model="prefix" id="prefix" />
                <input type="file" id="input-upload" class="mt-1 header__button" @change="upload($event)" />
            </span>
        </span>
        <p v-if="error != null">{{ error }}</p>
    </div>
</template>
  
<script>
import AWS from 'aws-sdk';
import { mapGetters } from 'vuex';
import { parseJwt } from '@/utils/auth.js'

export default {
    props: ['title'],
    data() {
        return {
            error: null,
            prefix: ""
        };
    },
    mounted() {
        const idToken = this.getTokens.idToken;
        if (idToken == null) {
            alert("No token");
            return;
        }
        const payload = parseJwt(idToken);
        this.prefix = `${payload['custom:tenant_id']}/client-documents/${payload['custom:client_id']}/shared-documents/`;
    },
    computed: {
        ...mapGetters(['getUser', 'getTokens', 'getConfiguration']),
    },
    methods: {
        async upload(event) {
            const files = event.target.files

            if (!files.length) return alert("Please choose a file to upload first!")

            try {
                const file = files[0]
                const fileName = file.name

                const idToken = this.getTokens.idToken;
                if (idToken == null) {
                    alert("Not token");
                    return;
                }
                const payload = parseJwt(idToken);
                console.log(payload);

                const identityProvider = this.getConfiguration.oauth_server.replace(/^https?:\/\//, '');
                console.log(idToken);
                const credentials = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: this.getConfiguration.identity_pool_id, // process.env.VUE_APP_AWS_COGNITO_IDENTITY_POOL,
                    Logins: {
                        [identityProvider]: idToken
                    }
                });
                const region = this.getConfiguration.region;
                AWS.config.update({
                    region, credentials
                });

                console.log(`${this.prefix}${fileName}`);

                // Use S3 ManagedUpload class as it supports multipart uploads
                var upload = new AWS.S3.ManagedUpload({
                    params: {
                        Bucket: this.getConfiguration.bucket,
                        Key: `${this.prefix}${fileName}`,
                        Body: file,
                        ContentType: file.type
                    }
                });

                var promise = upload.promise();

                promise.then(() => this.error = "Upload Ok", err => this.error = err.message);

            } catch (error) {
                this.error = error.message
                console.error('Failed to list S3 objects:', error);
            }
        }
    },
};
</script>
<style scoped>
.s3-object-list {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.s3-object-list > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.s3-object-list > span > span > * { 
    margin-left: 8px;
}
.s3-object-list__title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.s3-object-list > span > span > input[type=text] {
    width: 300px;
}

.s3-object-list__list {
    list-style-type: none;
    padding: 0;
}

.s3-object-list__item {
    margin-bottom: 8px;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
  