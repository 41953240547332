import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            user: null,
            tokens: null,
            authConfiguration: null,
            configuration: {}
        };
    },
    mutations: {
        setToken(state, payload) {
            state.tokens = payload;
        }, 
        setUser(state, payload) {
            console.info(payload)
            state.user = payload;
        },
        setConfiguration(state, payload) {
            state.configuration = payload;
        },
        loadLocalStorage(state) {
            console.log('loadLocalStorage')
            if (localStorage.getItem('configuration')) {
                state.configuration = JSON.parse(localStorage.configuration)
        }
    }
        // Add other mutations as needed
    },
    actions: {
        setTokens({ commit }, payload) {
            commit('setToken', payload);
        },
        setUser({ commit }, payload) {
            commit('setUser', payload);
        },
        setConfiguration({ commit }, payload) {
            commit('setConfiguration', payload);
        }
        // Add other actions as needed
    },
    getters: {
        getTokens(state) {
            return state.tokens;
        },
        getUser(state) {
            return state.user;
        },
        getConfiguration(state) {
            return state.configuration;
        }
    }
});

export default store;
